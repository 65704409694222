import {
  faBars,
  faCog,
  faCompressAlt,
  faExpandAlt,
  faSignInAlt,
  faUser,
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { lazy, Suspense } from 'react';
import { Link as RouterLink, useLocation, useMatch } from 'react-router-dom';
import styled from 'styled-components';
import type { OptionalAssignmentServiceRequest } from 'src/apis/assignment-service/use-optional-assignment-service';
import type { OptionalAuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import type { CapacityManagementRootResponse } from 'src/apis/capacity-management/types';
import type { DirectFcfsEntrypoint } from 'src/apis/direct-fcfs/types';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import type { RegularFcfsRoot } from 'src/apis/regular-fcfs/types';
import type { RemitReportingEntrypoint } from 'src/apis/remit-reporting/types';
import { ActionModeProvider } from 'src/components/action-mode';
import { FadeIn } from 'src/components/animation/fade-in';
import {
  Button,
  buttonTransition,
} from 'src/components/buttons-and-actions/button';
import { ButtonDropdown } from 'src/components/buttons-and-actions/button-dropdown';
import { GroupWrap } from 'src/components/group-wrap';
import { Container } from 'src/components/layout/container';
import {
  HeaderPrimaryBackground,
  HeaderPrimaryCenter,
} from 'src/components/layout/header/primary-nav';
import type { ActiveSection } from 'src/components/layout/header/types';
import type { MobileNavState } from 'src/components/layout/header/use-mobile-nav';
import type { UserMenuProps } from 'src/components/layout/header/user-menu';
import { UserMenu } from 'src/components/layout/header/user-menu';
import { Stack } from 'src/components/layout/stack';
import { Logo } from 'src/components/media-and-icons/logo';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { useAccounts } from 'src/hooks/use-accounts';
import type { Successful } from 'src/hooks/use-axios';
import { useBreakpoints } from 'src/hooks/use-breakpoints';
import { useCognitoUser } from 'src/hooks/use-cognito-user';
import { useFullwidth } from 'src/hooks/use-fullwidth';
import { useGmdFlags } from 'src/hooks/use-gdm-flags';
import { useOptionalAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { useReauthenticate } from 'src/hooks/use-reauthenticate';
import { useDeveloperTools } from 'src/pages/developer-tools/use-developer-tools';
import { Colors } from 'src/styles';
import { isLng } from 'src/utils/is-lng';
import type { StrictOmit } from 'src/utils/utility-types';

const MobileNav = styled.div`
  display: grid;
  grid-gap: 1rem;
  padding: 1rem 1.5rem;
  border-top: 0.1rem solid ${Colors.brand};
`;

const DeveloperToolsIcon = lazy(() => import('src/pages/developer-tools/icon'));

type Props = {
  activeSection: ActiveSection;
  authorizationService?: Successful<OptionalAuthorizationServiceRequest>;
  newLngUsed: boolean;
  organisationService?: Successful<OrganisationServiceRequest>;
  capacityManagementService?: CapacityManagementRootResponse;
  regularFcfsService?: RegularFcfsRoot;
  directFcfsService?: DirectFcfsEntrypoint;
  mobileNav: MobileNavState;
  remitReportingService?: RemitReportingEntrypoint;
  assignmentService?: Successful<OptionalAssignmentServiceRequest>;
};

const UserMenuInfo = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  font-size: 1.2rem;

  strong,
  span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  span {
    color: ${Colors.brandLight2};

    ${buttonTransition}

    button:hover &,
    button:focus & {
      color: ${Colors.brandSecondary};
    }
  }
`;

export const HeaderPrimary: FC<Props> = ({
  activeSection,
  authorizationService,
  newLngUsed,
  organisationService,
  capacityManagementService,
  regularFcfsService,
  directFcfsService,
  mobileNav,
  assignmentService,
  remitReportingService,
}) => {
  const [fullwidth, setFullwidth] = useFullwidth();
  const { minTablet, minDesktop, minWideDesktop } = useBreakpoints();
  const monolithUser = useOptionalAuthenticatedMonolithUser();
  const cognitoUser = useCognitoUser();
  const isAuthenticated = Boolean(cognitoUser);
  const gdmFlags = useGmdFlags();
  const isGdmSection = useMatch('/gdm/*');
  const { pathname, search } = useLocation();
  const [reauthenticate] = useReauthenticate();
  const accounts = useAccounts();

  const userMenuData: StrictOmit<UserMenuProps, 'show'> = {
    organisationService: organisationService?.response.data ?? null,
    authorizationService: authorizationService?.response.data ?? null,
    capacityManagementService: capacityManagementService ?? null,
    regularFcfsService,
    directFcfsService,
    assignmentService: assignmentService?.response.data ?? null,
    remitReportingService,
  };

  const sectionLinks = (
    <>
      {(!monolithUser || monolithUser.role !== 'SSO_ADMIN') && (
        <Link
          mode="primary"
          to="/transport/auctions"
          isActive={activeSection.transport}
        >
          Transport
        </Link>
      )}

      {(!monolithUser ||
        (monolithUser.isSso ? !isLng(monolithUser) : !monolithUser.isTso)) && (
        <Link
          mode="primary"
          to="/storage/offers"
          isActive={activeSection.storage}
        >
          Storage
        </Link>
      )}

      {newLngUsed ? (
        // new lng section
        <Link mode="primary" to="/lng" isActive={activeSection.lngNew}>
          LNG
        </Link>
      ) : (
        // old lng section
        <>
          {(!monolithUser ||
            (monolithUser.isSso
              ? isLng(monolithUser)
              : !monolithUser.isTso)) && (
            <ExternalLink
              mode="primary"
              href={`${PRISMA_CONFIG.angularUrl}/#/lng/offers`}
              isActive={activeSection.lng}
            >
              LNG
            </ExternalLink>
          )}
        </>
      )}

      <Link mode="primary" to="/reporting" isActive={activeSection.reporting}>
        Reporting
      </Link>

      <Link mode="primary" to="/umm">
        UMM
      </Link>

      {(gdmFlags.userCanAcces || isGdmSection) && (
        <Link mode="primary" to="/gdm" isActive={activeSection.gdm}>
          GDM
        </Link>
      )}

      <Link mode="primary" to="/aggregate-eu">
        AggregateEU
      </Link>
    </>
  );

  const login = (
    <>
      {!reauthenticate && (
        <Link mode="primary" to="/registration/wizard/start">
          Register
        </Link>
      )}

      <Link
        mode={minTablet ? 'button-inverse' : 'primary'}
        to={
          // if someone presses on the login button from _within_ the login
          // section, we just want to reuse the search params
          pathname.startsWith('/login')
            ? { pathname: '/login', search }
            : {
                pathname: '/login',
                search: `redirectUrl=${encodeURIComponent(
                  location.href.replace('/angular', '/platform')
                )}`,
              }
        }
      >
        {minTablet && !minDesktop ? (
          <FontAwesomeIcon icon={faSignInAlt} aria-label="Log in" />
        ) : (
          <>Log in</>
        )}
      </Link>
    </>
  );

  const developerTools = useDeveloperTools();

  return (
    <HeaderPrimaryBackground>
      <Container>
        <HeaderPrimaryCenter>
          <Stack templateColumns="auto 1fr" gap={minDesktop ? 3.4 : 2}>
            <RouterLink
              to={
                monolithUser && !monolithUser.isSso
                  ? '/platform/#/start'
                  : cognitoUser
                    ? '/dashboard'
                    : '/'
              }
            >
              <Logo />
            </RouterLink>

            <FadeIn>
              {minTablet && (
                <GroupWrap gap={minDesktop ? [0.5, 3.4] : [0.5, 2]}>
                  {sectionLinks}
                </GroupWrap>
              )}
            </FadeIn>
          </Stack>

          <FadeIn>
            <Stack flow="column" gap={2}>
              {!minTablet && (
                <Button
                  onClick={() =>
                    mobileNav.setValue(
                      mobileNav.value === 'primary' ? null : 'primary'
                    )
                  }
                >
                  <FontAwesomeIcon
                    icon={faBars}
                    aria-label="Toggle Main Navigation"
                  />
                </Button>
              )}

              {minTablet &&
                (isAuthenticated && !reauthenticate ? (
                  <>
                    <ButtonDropdown
                      data-testid="toggle-settings-menu"
                      label={
                        <FontAwesomeIcon
                          icon={faCog}
                          aria-label="Toggle Settings Menu"
                        />
                      }
                    >
                      <UserMenu
                        {...userMenuData}
                        show={{
                          personalSettings: false,
                          companySettings: true,
                          transportSettings: true,
                          storageSettings: true,
                          shipperSettings: true,
                          otherSettings: false,
                          reportingSettings: true,
                        }}
                      />
                    </ButtonDropdown>

                    <ButtonDropdown
                      label={
                        <Stack
                          flow="column"
                          gap={0.5}
                          style={{ maxWidth: '16rem' }}
                        >
                          <FontAwesomeIcon
                            icon={faUser}
                            aria-label="Toggle User Menu"
                          />
                          {accounts.current && minDesktop && (
                            <UserMenuInfo>
                              {accounts.current.organisationName && (
                                <Stack>
                                  <strong>
                                    {accounts.current.organisationName}
                                  </strong>
                                </Stack>
                              )}
                              <span>{accounts.current.email}</span>
                            </UserMenuInfo>
                          )}
                        </Stack>
                      }
                      data-testid="toggle-user-menu"
                    >
                      <UserMenu
                        {...userMenuData}
                        show={{
                          personalSettings: true,
                          companySettings: false,
                          transportSettings: false,
                          storageSettings: false,
                          shipperSettings: false,
                          otherSettings: true,
                          reportingSettings: false,
                        }}
                      />
                    </ButtonDropdown>
                  </>
                ) : (
                  login
                ))}

              {minWideDesktop && (
                <Button
                  mode="icon-inverse"
                  onClick={() => setFullwidth(!fullwidth)}
                >
                  {fullwidth ? (
                    <FontAwesomeIcon
                      icon={faCompressAlt}
                      aria-label="Decrease width of website"
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faExpandAlt}
                      aria-label="Increase width of website"
                    />
                  )}
                </Button>
              )}
              {minWideDesktop && developerTools && (
                <Suspense fallback={<div style={{ width: 18 }} />}>
                  <DeveloperToolsIcon />
                </Suspense>
              )}
            </Stack>
          </FadeIn>
        </HeaderPrimaryCenter>
      </Container>

      {!minTablet && mobileNav.value === 'primary' && (
        <MobileNav onClick={() => mobileNav.setValue(null)}>
          <ActionModeProvider value={{ mode: 'link-primary' }}>
            {sectionLinks}
            {isAuthenticated && !reauthenticate ? (
              <UserMenu
                {...userMenuData}
                show={{
                  personalSettings: true,
                  companySettings: true,
                  transportSettings: true,
                  storageSettings: true,
                  shipperSettings: true,
                  otherSettings: true,
                  reportingSettings: true,
                }}
              />
            ) : (
              <>
                {!reauthenticate && (
                  <Link to="/registration/wizard/start">Register</Link>
                )}
                <Link
                  to={
                    // if someone presses on the login button from _within_ the login
                    // section, we just want to reuse the search params
                    pathname.startsWith('/login')
                      ? { pathname: '/login', search }
                      : {
                          pathname: '/login',
                          search: `redirectUrl=${encodeURIComponent(
                            location.href
                          )}`,
                        }
                  }
                >
                  Log in
                </Link>
              </>
            )}
          </ActionModeProvider>
        </MobileNav>
      )}
    </HeaderPrimaryBackground>
  );
};
