import { useAxios } from 'src/hooks/use-axios';

type RequestProps = {
  url: string;
  data?: {}; // due to technical implementation on BE we need to send just a body, eventhough it's not used
};
export function useWithdrawOrganisationalAssignment() {
  return useAxios(
    (axios, baseConfig, { url, data = {} }: RequestProps) =>
      axios.request({
        ...baseConfig,
        method: 'DELETE',
        url,
        data,
      }),
    {
      neededOnPageLoad: false,
    }
  );
}

export type WithdrawOrganisationalAssignmentsRequest = ReturnType<
  typeof useWithdrawOrganisationalAssignment
>;
