import type { FC } from 'react';
import { lazy, useMemo } from 'react';
import { useMemoOne } from 'use-memo-one';
import type { OperatorUserAssignmentParams } from 'src/apis/assignment-service/types';
import { useAssignedShipperUsersForOperator } from 'src/apis/assignment-service/use-assigned-shipper-users-for-operator';
import { type AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { ContractManagementRequest } from 'src/apis/contract-management/use-contract-management';
import type { Shipper } from 'src/apis/monolith/types';
import type { AssignedShippersRequestParams } from 'src/apis/monolith/use-assigned-shippers-page';
import { useAssignedShippersPage } from 'src/apis/monolith/use-assigned-shippers-page';
import { useOldUserId } from 'src/apis/monolith/use-get-old-user-id';
import type { ShipperPlatformIdsRequest } from 'src/apis/monolith/use-shipper-platform-ids';
import { type AssignedShipperDetailsRequest } from 'src/apis/organisation-service/use-assigned-shipper-details';
import type { OrganisationServiceRequest } from 'src/apis/organisation-service/use-organisation-service';
import { Alert } from 'src/components/feedback/alert';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { isNetworkError } from 'src/hooks/use-axios';
import { useAssignedShipperUsersPageParams } from 'src/pages/shippers/details/information/components/shipper-users-table/use-page-params-new-user-table';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

export const ShipperInformation: FC<{
  shipper: Shipper;
  contractManagement: ContractManagementRequest;
  shipperPlatformIds: Successful<ShipperPlatformIdsRequest>;
  organisationService: Successful<OrganisationServiceRequest>;
  assignedShipperDetails: Successful<AssignedShipperDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
  shipperUuid: string;
}> = ({
  shipper,
  contractManagement,
  shipperPlatformIds,
  organisationService,
  assignmentService,
  shipperUuid,
  assignedShipperDetails,
}) => {
  if (!organisationService.response.data._links.getAssignedShipperDetails)
    throw new Forbidden();

  const eic = shipper.identifierList.filter((i) => i.type === 'EIC')[0].id;
  const requestParams = useMemoOne<AssignedShippersRequestParams>(
    () => ({ eic }),
    [eic]
  );
  const assignedShipper = useAssignedShippersPage(requestParams);
  const oldUserId = useOldUserId();
  const pageParams = useAssignedShipperUsersPageParams();

  const params = useMemo(() => {
    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      userName: pageParams.value.userName,
      userEmail: pageParams.value.userEmail,
      userAssignmentStatus: pageParams.value.userAssignmentStatus.length
        ? pageParams.value.userAssignmentStatus
        : [
            'APPROVED',
            'CANCELLED',
            'TEMPORARILY_DISABLED',
            'WAITING_FOR_APPROVAL',
          ],
    } satisfies OperatorUserAssignmentParams;
  }, [pageParams.value]);

  const assignedShipperUsersForOperatorRequest =
    useAssignedShipperUsersForOperator({
      url: assignmentService.response.data._links.operatorUsersAssignments
        ?.href,
      shipperId: shipperUuid,
      params,
    });

  if (assignedShipper.error) {
    return (
      <Alert type="error">
        Sorry, an unknown error occurred. Please, check back later.
      </Alert>
    );
  }

  if (
    !assignedShipper.response ||
    !oldUserId.response ||
    !assignmentService.response ||
    (!contractManagement.response &&
      !isNetworkError(contractManagement.error)) ||
    !assignedShipperUsersForOperatorRequest?.response === null
  ) {
    return <PageSpinner />;
  }

  // this is actually a shipper user
  if (oldUserId.response.data.customerId !== undefined) throw new Forbidden();

  if (
    !assignedShipper.response.data ||
    !Array.isArray(assignedShipper.response.data.data) ||
    !(assignedShipper.response.data.data.length > 0) ||
    assignedShipper.response.data.data[0].state === 'CANCELED'
  )
    return (
      <Alert type="info">
        {shipper.name} did not request assignment with you on the platform or
        the assignment has been already canceled.
      </Alert>
    );

  return (
    <Page
      shipper={shipper}
      assignedShipper={assignedShipper.response.data.data[0]}
      contractManagement={contractManagement}
      assignedShippersRequest={assignedShipper}
      requestParams={requestParams}
      shipperPlatformIds={shipperPlatformIds}
      assignmentService={assignmentService}
      tsoRefId={oldUserId.response.data.tsoRefId}
      assignedShipperDetails={assignedShipperDetails}
      assignedShipperUsersForOperatorRequest={
        assignedShipperUsersForOperatorRequest
      }
    />
  );
};
