import type { FC } from 'react';
import { Suspense, useMemo } from 'react';
import { Navigate, Route, useLocation } from 'react-router-dom';
import type { AssignedUsersParams } from 'src/apis/assignment-service/types';
import { useAssignedShippers } from 'src/apis/assignment-service/use-assigned-shippers';
import { useAssignedUsers } from 'src/apis/assignment-service/use-assigned-users';
import {
  useAssignmentServicePublic,
  type AssignmentServiceRequest,
} from 'src/apis/assignment-service/use-assignment-service-root';
import { useOrganisationAssignment } from 'src/apis/assignment-service/use-organisation-assignment';
import { useUserAssignment } from 'src/apis/assignment-service/use-user-assignment';
import { useUserAssignmentApplication } from 'src/apis/assignment-service/use-user-assignment-applications';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { PageHeader } from 'src/components/layout/page-header';
import { Stack } from 'src/components/layout/stack';
import { RootRoutes } from 'src/components/root-routes';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { usePortal } from 'src/hooks/use-portal';
import { CreditLimitsOverview } from 'src/pages/shippers/credit-limits';
import { ShippersDocumentsOverview } from 'src/pages/shippers/documents';
import { InvoicesOverview } from 'src/pages/shippers/invoices';
import { AssignedUsers } from 'src/pages/shippers/organisations-and-users';
import { useAssignedUsersPageParams } from 'src/pages/shippers/organisations-and-users/use-page-params';
import { ShipperNavigationBar } from 'src/pages/shippers/shipper-navigation-bar';
import { Forbidden } from 'src/utils/errors';

export const ShippersView: FC = () => {
  const actionPortal = usePortal();
  const assignmentService = useAssignmentServicePublic();

  if (!assignmentService.response) return <PageSpinner />;

  return (
    <Stack gap={1}>
      <PageHeader title="Shippers" />

      <ShipperNavigationBar
        actionPortal={actionPortal}
        assignmentService={assignmentService}
      />

      <Suspense fallback={<PageSpinner />}>
        <RootRoutes>
          <Route
            path="/shippers/documents"
            element={<ShippersDocumentsOverview />}
          />

          <Route
            path="/shippers/invoices"
            element={<InvoicesOverview actionPortal={actionPortal} />}
          />

          <Route
            path="/shippers/organisations-and-users"
            element={<AssignedUsers assignmentService={assignmentService} />}
          />

          <Route
            path="/shippers/organisations-and-users/notifications-redirect"
            element={
              <EmailNotificationsRedirect
                assignmentService={assignmentService}
              />
            }
          />

          <Route path="*" element={<ThrowNotFound />} />

          <Route
            path="/shippers/credit-limit"
            element={<CreditLimitsOverview />}
          />
        </RootRoutes>
      </Suspense>
    </Stack>
  );
};

const EmailNotificationsRedirect: FC<{
  assignmentService: Successful<AssignmentServiceRequest>;
}> = ({ assignmentService }) => {
  if (!assignmentService.response.data._links.assignedUsers)
    throw new Forbidden();

  const pageParams = useAssignedUsersPageParams();
  const params = useMemo(() => {
    return {
      sortBy: pageParams.value.sortBy,
      sortDirection: pageParams.value.sortDirection,
      limit: pageParams.value.pageSize,
      offset: pageParams.value.start,
      name: pageParams.value.name,
      shipperId: pageParams.value.shipperId?.value,
      email: pageParams.value.email,
      role: pageParams.value.role,
      userAssignmentStatus: pageParams.value.userAssignmentStatus,
      organisationAssignmentStatus:
        pageParams.value.organisationAssignmentStatus,
      userStatus: pageParams.value.userStatus,
      tradingMarket: pageParams.value.tradingMarket,
    } satisfies AssignedUsersParams;
  }, [pageParams.value]);

  const assignedUsers = useAssignedUsers({
    url: assignmentService.response.data._links.assignedUsers.href,
    params,
  });
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const orgAssignmentId = queryParams.get('organisationAssignmentId');
  const userAssignmentApplicationId = queryParams.get(
    'userAssignmentApplicationId'
  );
  const userAssignmentId = queryParams.get('userAssignmentId');

  const userAssignmentApplication = useUserAssignmentApplication({
    assignmentService,
    userAssignmentApplicationId,
  });

  const userAssignment = useUserAssignment({
    assignmentService,
    userAssignmentId,
  });

  const organisationAssignment = useOrganisationAssignment({
    assignmentService,
    orgAssignmentId,
  });

  const assignedShippers = useAssignedShippers({
    assignedUsers,
  });

  if (
    userAssignment?.response === null ||
    userAssignmentApplication?.response === null ||
    organisationAssignment?.response === null ||
    !assignedShippers.response
  )
    return <PageSpinner />;

  // In this case we should always have a shipper as organisationAssignment endpoint
  // has a mandatory field shipperId
  // If there's no corresponding shipper, that means we have some brobls on BE
  // However, from FE perspective, it should be always there for the case, when we have
  // valid organisationAssignment.response
  const shipper = assignedShippers.response.data._embedded.items.filter(
    (shipper) =>
      shipper.shipperId === organisationAssignment?.response.data.shipperId
  )[0];

  const userEmail =
    userAssignmentApplication?.response.data.userEmail ??
    userAssignment?.response.data.userEmail;

  if (organisationAssignment?.response) {
    return (
      <Navigate
        replace
        to={{
          pathname: `/shippers/organisations-and-users`,
          search: `shipperId[label]=${encodeURIComponent(shipper.shipperName)}&shipperId[value]=${shipper.shipperId}${shipper.shipperEic ? `&shipperId[hint]=${encodeURIComponent(shipper.shipperEic)}` : ''}`,
        }}
      />
    );
  } else {
    return (
      <Navigate
        replace
        to={{
          pathname: `/shippers/organisations-and-users`,
          search: `?email=${encodeURIComponent(userEmail as string)}`,
        }}
      />
    );
  }
};
