import type { FC } from 'react';
import { lazy } from 'react';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useOrganisationSettings } from 'src/apis/authorization-service/use-organisation-settings';
import { usePermissionGroups } from 'src/apis/authorization-service/use-permission-groups';
import { PageSpinner } from 'src/components/spinner-container';
import type { Successful } from 'src/hooks/use-axios';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(() => import('./page'));

type Props = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export const PermissionGroupsOverview: FC<Props> = ({
  authorizationService,
}) => {
  const permissionGroups = usePermissionGroups({
    authorizationService,
  });
  const organisationSettings = useOrganisationSettings({
    authorizationService,
  });
  if (!permissionGroups) throw new Forbidden();

  if (!permissionGroups.response || !organisationSettings?.response)
    return <PageSpinner />;

  return (
    <Page
      permissionGroups={permissionGroups}
      organisationSettings={organisationSettings}
      isNewFlowEnabled={authorizationService.response.data.isNewFlowEnabled}
    />
  );
};
