import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { UsersAssignmentByOperator } from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

export function useUserAssignmentApplication({
  assignmentService,
  userAssignmentApplicationId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  userAssignmentApplicationId: string | null;
}) {
  const userAssignmentApplication = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<UsersAssignmentByOperator>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !userAssignmentApplicationId ||
      !assignmentService.response.data._links.getUserAssignmentApplication
    )
      return;
    const url = urlTemplate
      .parse(
        assignmentService.response.data._links.getUserAssignmentApplication.href
      )
      .expand({ userAssignmentApplicationId });

    userAssignmentApplication.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAssignmentApplicationId]);

  if (
    !userAssignmentApplicationId ||
    !assignmentService.response.data._links.getUserAssignmentApplication
  )
    return;

  return userAssignmentApplication;
}

export type UserAssignmentApplicationRequest = ReturnType<
  typeof useUserAssignmentApplication
>;
