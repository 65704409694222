import type { FC } from 'react';
import type { MyAssignmentsOptionalRequest } from 'src/apis/assignment-service/use-my-assignments';
import type { UserAssignmentsForOperatorRequest } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { EmptyValue } from 'src/components/data-display/empty-value';
import { Table } from 'src/components/data-display/smart-table';
import { SpecificSortHeader, Td, Th } from 'src/components/data-display/table';
import { AssignmentStatusDisplay } from 'src/components/domain-specifics/assignements/assignment-status';
import type { Successful } from 'src/hooks/use-axios';
import { UserAssignmentActions } from 'src/pages/operators/assignments/details/table-actions';
import type { AssignmentsAndContactsParams } from 'src/pages/operators/assignments/details/use-page-params';

type Props = {
  userAssignments: Successful<UserAssignmentsForOperatorRequest>;
  pageParams: AssignmentsAndContactsParams;
  operatorDetails: PublicOperatorDetailsResponse;
  assignmentDetailsRequest: Successful<MyAssignmentsOptionalRequest>;
};

export const UserAssignmentsByOperatorTable: FC<Props> = ({
  userAssignments,
  pageParams: { userAssignmentsByOperator: pageParams },
  operatorDetails,
  assignmentDetailsRequest,
}) => {
  return (
    <Table
      pending={userAssignments.pending}
      setId={(assignment) => assignment.userAssignmentApplicationId}
      data={userAssignments.response.data._embedded.items}
      paginated={userAssignments.response.data}
      cols={[
        {
          key: 'userName',
          head: (
            <SpecificSortHeader
              label={pageParams.filter.userName.label}
              params={pageParams}
              column="USER_NAME"
              sortParam="sortBy"
              directionParam="sortDirection"
            />
          ),
          body: (item) => (
            <Td data-testid={`userName-${item.userAssignmentApplicationId}`}>
              {item.userName}
            </Td>
          ),
        },
        {
          key: 'userEmail',
          head: (
            <SpecificSortHeader
              label={pageParams.filter.userEmail.label}
              params={pageParams}
              column="USER_EMAIL"
              sortParam="sortBy"
              directionParam="sortDirection"
            />
          ),
          body: (item) => (
            <Td data-testid={`userEmail-${item.userAssignmentApplicationId}`}>
              {item.userEmail}
            </Td>
          ),
        },
        {
          key: 'status',
          head: (
            <SpecificSortHeader
              label={pageParams.filter.status.label}
              params={pageParams}
              column="USER_ASSIGNMENT_STATUS"
              sortParam="sortBy"
              directionParam="sortDirection"
            />
          ),
          body: (item) => (
            <Td
              data-testid={`user-assignment-${item.userAssignmentApplicationId}`}
            >
              {item.status ? (
                <AssignmentStatusDisplay
                  value={item.status}
                  data-testid={`user-assignment-status-${item.userAssignmentApplicationId}`}
                />
              ) : (
                <EmptyValue />
              )}
            </Td>
          ),
        },
        {
          key: 'actions',
          width: '12rem',
          head: <Th align="center">Actions</Th>,
          body: (item) => {
            return (
              <Td
                align="center"
                data-testid={`actions-${item.userAssignmentApplicationId}`}
              >
                <UserAssignmentActions
                  userAssignmentDetails={item}
                  userAssignmentDetailsRequest={userAssignments}
                  operatorDetails={operatorDetails}
                  assignmentDetailsRequest={assignmentDetailsRequest}
                />
              </Td>
            );
          },
        },
      ]}
      empty={
        pageParams.hasChangedFilters
          ? {
              label:
                'No results match your search criteria. Try changing or resetting the filters.',
              button: { onClick: pageParams.resetFilters },
            }
          : {
              label: 'No user assignments available.',
            }
      }
      data-testid="user-assignments-list"
    />
  );
};
