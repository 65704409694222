import { createContext, useContext, useEffect, useState } from 'react';
import { useAxios } from 'src/hooks/use-axios';

type EntrypointAuthorization = {
  canView: boolean;
  canManage: boolean;
};

export type TrFlags = {
  userCanAccess: boolean;
  authorization?: EntrypointAuthorization;
  error?: unknown;
};

const defaultTrFlags: TrFlags = {
  userCanAccess: false,
};

const TrFlagsContext = createContext<TrFlags>(defaultTrFlags);

function useEntrypointAuthRequest() {
  const [error, setError] = useState<unknown>(undefined);

  const request = useAxios(
    (axios, baseConfig) =>
      axios.request<{ canView: boolean; canManage: boolean }>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.transparencyReporting}/entrypoint/authorization`,
        method: 'GET',
        timeout: 5000,
      }),
    {
      neededOnPageLoad: true,
      onError: setError,
    }
  );

  useEffect(() => {
    request.execute();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loaded: Boolean(request.response || error),
    data: request.response?.data,
    error,
  };
}

type TrFlagsValue = {
  loaded: boolean;
  value: TrFlags;
};

export function useTrFlagsValue() {
  const { loaded, error, data } = useEntrypointAuthRequest();
  return {
    loaded,
    value: {
      userCanAccess: data?.canView ?? false,
      authorization: data,
      error,
    },
  } as TrFlagsValue;
}

export function useTrFlags() {
  return useContext(TrFlagsContext);
}

export function useTrAuthorization() {
  return useContext(TrFlagsContext).authorization;
}

export const TrFlagsProvider = TrFlagsContext.Provider;
