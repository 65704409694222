import { lazy, type FC } from 'react';
import { Route } from 'react-router-dom';
import { ThrowNotFound } from 'src/components/feedback/not-found';
import { RootRoutes } from 'src/components/root-routes';
import { useTitle } from 'src/hooks/use-title';

const Page = lazy(() => import('./page'));

export const RemitReportingSettings: FC = () => {
  useTitle('Settings - REMIT Reporting');

  return (
    <RootRoutes>
      <Route path="/settings/remit-reporting" element={<Page />} />
      <Route path="*" element={<ThrowNotFound />} />
    </RootRoutes>
  );
};
