import { z } from 'zod';
import type { UserAssignmentsForShippersSortBy } from 'src/apis/assignment-service/types';
import { assignmentStatusValues } from 'src/apis/monolith/mappings';
import {
  ascSortDirectionSchema,
  getPaginationSchema,
} from 'src/components/form/zod-schemas';
import {
  preprocessAsArray,
  preprocessAsBoolean,
  requiredOutput,
} from 'src/components/form/zod-utilities';

export function getShipperInformationPageParamsForAssignmentStatusSchema() {
  return z
    .object({
      sortColumn: z.string().default('name'),
      sortAscending: z.preprocess(
        preprocessAsBoolean,
        z.boolean().default(true)
      ),
      name: z.string().nullable().default(null),
      email: z.string().nullable().default(null),

      status: z.preprocess(
        preprocessAsArray,
        z.array(z.enum(assignmentStatusValues)).default([])
      ),
    })
    .merge(getPaginationSchema());
}

export const sortByValues = [
  'NAME',
  'EMAIL',
  'USER_ASSIGNMENT_STATUS',
] as const satisfies readonly UserAssignmentsForShippersSortBy[];

export function getAssignedShipperUsersPageParamsForOperatorsSchema() {
  return z
    .object({
      sortBy: z.enum(sortByValues).default('NAME'),
      sortDirection: ascSortDirectionSchema,
      userName: z.string().nullable().default(null),
      userEmail: z.string().nullable().default(null),
      userAssignmentStatus: z.preprocess(
        preprocessAsArray,
        z
          .array(
            z.enum([
              'WAITING_FOR_APPROVAL',
              'APPROVED',
              'TEMPORARILY_DISABLED',
              'CANCELLED',
              'NOT_REQUESTED',
            ])
          )
          .default([])
      ),
    })
    .merge(getPaginationSchema());
}

export const approveOrganisationAssignmentSchema = z.object({
  tradingMarkets: z
    .enum(['PRIMARY', 'SECONDARY', 'PRIMARY_AND_SECONDARY'])
    .default('PRIMARY'),
});

export const rejectOrganisationAssignmentSchema = z.object({
  reason: z.string().nullable().transform(requiredOutput()),
});
