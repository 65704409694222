import { faDownload, faExternalLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC, ReactNode } from 'react';
import { useMemo } from 'react';
import styled from 'styled-components';
import type {
  AdditionalRequiredDocument,
  RequiredCreditRatingDocument,
  RequiredDocument,
} from 'src/apis/assignment-service/types';
import type { AssignmentInfoRequest } from 'src/apis/assignment-service/use-assignment-info';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import { useDownloadRequiredInformation } from 'src/apis/assignment-service/use-download-required-document';
import type { PublicRequiredDocumentsRequest } from 'src/apis/assignment-service/use-public-required-documents';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import type { OptionalOrganisationDetailsRequest } from 'src/apis/organisation-service/use-organisation-details';
import { Button } from 'src/components/buttons-and-actions/button';
import { Card } from 'src/components/data-display/card';
import { CardDivider } from 'src/components/dividers';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink, Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import type { Successful } from 'src/hooks/use-axios';
import { DeliveryMethodLabels } from 'src/pages/settings/assignment-settings/required-information/edit/schema';

type Props = {
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  tsoDetails: PublicOperatorDetailsResponse;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  organisationDetails?: Successful<OptionalOrganisationDetailsRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
};

export const CompanyRequiredDocuments: FC<Props> = ({
  publicRequiredDocuments,
  tsoDetails,
  assignmentInfo,
  organisationDetails,
  assignmentService,
}) => {
  return (
    <Card id="documents">
      <Stack gap={1}>
        <Heading mode="card">Documents</Heading>
        <CardDivider />
        <ExternalLink href={tsoDetails.gtcUrl}>
          General Terms and Conditions
        </ExternalLink>
        <CardDivider />

        <AssignmentPublicRequiredDocuments
          publicRequiredDocuments={publicRequiredDocuments}
          assignmentInfo={assignmentInfo}
          organisationDetails={organisationDetails}
          assignmentService={assignmentService}
        />
      </Stack>
    </Card>
  );
};

const AssignmentPublicRequiredDocuments: FC<{
  publicRequiredDocuments: Successful<PublicRequiredDocumentsRequest>;
  assignmentInfo: Successful<AssignmentInfoRequest>;
  assignmentService: Successful<AssignmentServiceRequest>;
  organisationDetails?: Successful<OptionalOrganisationDetailsRequest>;
}> = ({
  publicRequiredDocuments,
  assignmentInfo,
  organisationDetails,
  assignmentService,
}) => {
  const { data } = publicRequiredDocuments.response;

  const anyRequiredDocuments: boolean = useMemo(
    () =>
      [
        data.companyRegisterExtract,
        data.currentCompanyReport,
        data.mostRecentFullYearFinancialReport,
        data.mostRecentInterimFinancialReport,
        data.creditSolvencyCheck,
        data.poa,
        data.creditRating,
      ].some((doc) => doc && doc.acceptedDeliveryMethods.length > 0),
    [data]
  );

  return (
    <Stack gap={0.5}>
      <p>
        Following documents are required when requesting an assignment with this
        operator:
      </p>

      {!anyRequiredDocuments && <p>There are no documents.</p>}

      {anyRequiredDocuments && (
        <>
          <DocumentTitle
            allNone={
              !data.companyRegisterExtract &&
              !data.currentCompanyReport &&
              !data.mostRecentFullYearFinancialReport &&
              !data.mostRecentInterimFinancialReport &&
              !data.creditSolvencyCheck &&
              !data.poa &&
              !data.creditRating
            }
          >
            New Organisation Must Provide
          </DocumentTitle>
          <Stack gap={1.5} data-testid="documents-list">
            {data.companyRegisterExtract && (
              <DocumentView
                label="Company Register Extract"
                document={data.companyRegisterExtract}
              />
            )}
            {data.currentCompanyReport && (
              <DocumentView
                label="Current Company Report"
                document={data.currentCompanyReport}
              />
            )}
            {data.mostRecentFullYearFinancialReport && (
              <DocumentView
                label="Most Recent (Full-Year) Financial Report"
                document={data.mostRecentFullYearFinancialReport}
              />
            )}
            {data.mostRecentInterimFinancialReport && (
              <DocumentView
                label="Most Recent (Interim) Financial Report"
                document={data.mostRecentInterimFinancialReport}
              />
            )}
            {data.creditSolvencyCheck && (
              <DocumentView
                label="Credit/Solvency Check Document"
                document={data.creditSolvencyCheck}
              />
            )}
            {data.poa && (
              <DocumentView
                label="PRISMA Shipper Admin Power of Attorney"
                document={data.poa}
              />
            )}
            {data.creditRating && (
              <DocumentView
                label="Credit Rating Certification"
                document={data.creditRating}
                agency={data.creditRating.agency}
              />
            )}
          </Stack>
        </>
      )}

      {data.additionalOrganisationDocuments.length > 0 && (
        <Stack gap={1.5} data-testid="custom-documents-list">
          {data.additionalOrganisationDocuments.map((organisationDocument) => (
            <CustomDocumentView
              key={organisationDocument.name}
              additionalDocument={organisationDocument}
            />
          ))}
        </Stack>
      )}

      {data.additionalUserDocuments.length > 0 && (
        <>
          <DocumentTitle>New Users Must Provide</DocumentTitle>
          <Stack gap={1.5} data-testid="user-documents-list">
            {data.additionalUserDocuments.map((userDocument) => (
              <CustomDocumentView
                key={userDocument.name}
                additionalDocument={userDocument}
              />
            ))}
          </Stack>
        </>
      )}

      {/* This check for assignmentApplicationEnabled is treaded as FF. To be removed once BE will be fully released on PROD */}
      {assignmentService.response.data._links.assignmentApplicationEnabled &&
        assignmentInfo.response.data.dvgwCodeRequiredForAssignment && (
          <Stack gap={1.5} data-testid="dvgw-code-configuration">
            <Spacer />
            <p>
              This operator needs a supplier DVGW code for an assignment.
              {/* check if the shipper is logged-in */}
              {organisationDetails?.response.data._links.shipperDetails && (
                <>
                  {' '}
                  You have to provide this code in your{' '}
                  <Link
                    mode="default-underlined"
                    to="/settings/organisation/company-information"
                  >
                    Shipper Details
                  </Link>
                  .
                </>
              )}
            </p>
          </Stack>
        )}
    </Stack>
  );
};

const SpacedStack = styled(Stack)`
  margin-top: 1rem;
`;

export const DocumentTitle: FC<{ allNone?: boolean; children: ReactNode }> = ({
  children,
  allNone = false,
}) => (
  <SpacedStack flow="column" justifyContent="space-between">
    <h4>{children}</h4>
    {!allNone && <h4>via</h4>}
  </SpacedStack>
);

export const DocumentView: FC<{
  label: string;
  document?: RequiredDocument | RequiredCreditRatingDocument;
  agency?: string | null;
}> = ({ label, document, agency }) => {
  if (!document || !document.acceptedDeliveryMethods.length) return null;

  return (
    <Stack flow="column" justifyContent="space-between">
      <p>
        {label} {agency && '(' + 'Agency: ' + agency + ')'}
      </p>
      <p>
        {document.acceptedDeliveryMethods
          .map((method) => DeliveryMethodLabels[method])
          .join(', ')}{' '}
      </p>
    </Stack>
  );
};

type CustomDocumentViewProps = {
  additionalDocument: AdditionalRequiredDocument;
};

export const CustomDocumentView: FC<CustomDocumentViewProps> = ({
  additionalDocument,
}) => {
  const download = useDownloadRequiredInformation();

  return (
    <>
      {additionalDocument.url && (
        <Stack flow="column" justifyContent="space-between">
          <ExternalLink
            href={additionalDocument.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon icon={faExternalLink} /> {additionalDocument.name}
          </ExternalLink>
          {additionalDocument.acceptedDeliveryMethods
            .map((method) => DeliveryMethodLabels[method])
            .join(', ')}
        </Stack>
      )}
      {additionalDocument.document &&
        additionalDocument.document._links.download.href && (
          <Stack flow="column" justifyContent="space-between">
            <Button
              mode="link"
              onClick={() =>
                download.execute({
                  url: additionalDocument.document!._links.download.href,
                })
              }
              disabled={download.pending}
            >
              <FontAwesomeIcon icon={faDownload} /> {additionalDocument.name}
            </Button>
            {additionalDocument.acceptedDeliveryMethods
              .map((method) => DeliveryMethodLabels[method])
              .join(', ')}
          </Stack>
        )}
      {!additionalDocument.url && !additionalDocument.document && (
        <Stack flow="column" justifyContent="space-between">
          <p>{additionalDocument.name} </p>
          <p>
            {additionalDocument.acceptedDeliveryMethods
              .map((method) => DeliveryMethodLabels[method])
              .join(', ')}
          </p>
        </Stack>
      )}
    </>
  );
};
