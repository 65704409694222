import { type FC, lazy } from 'react';
import { useShipperReportGenerationSetting } from 'src/apis/monolith/use-shipper-reporting-settings';
import {
  PageSpinner,
  SpinnerContainer,
} from 'src/components/spinner-container';
import { useAuthenticatedMonolithUser } from 'src/hooks/use-monolith-user';
import { Forbidden } from 'src/utils/errors';

const Page = lazy(
  () => import('src/pages/settings/reporting/standard-reports/page')
);

export const StandardReportSettings: FC = () => {
  const monolithUser = useAuthenticatedMonolithUser();
  if (!monolithUser.isShipper) throw new Forbidden();

  const generationSetting = useShipperReportGenerationSetting(
    monolithUser.referenceId
  );

  if (!generationSetting.response) {
    return <PageSpinner />;
  }

  return (
    <SpinnerContainer pending={generationSetting.pending}>
      <Page monolithUser={monolithUser} generationSetting={generationSetting} />
    </SpinnerContainer>
  );
};
