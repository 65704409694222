import { faBan } from '@fortawesome/pro-solid-svg-icons';
import type { FC } from 'react';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Stack } from 'src/components/layout/stack';
import { ExternalLink } from 'src/components/navigation/link';
import { Modal } from 'src/components/overlay/modal';
import { useCountdown } from 'src/hooks/use-countdown';

type Props = {
  close: () => void;
  onConfirm: () => void;
  pending: boolean;
  operatorDetails: PublicOperatorDetailsResponse;
  userName?: string;
};

export const WithdrawUserAssignmentModal: FC<Props> = ({
  close,
  onConfirm,
  pending,
  operatorDetails,
  userName,
}) => {
  const seconds = useCountdown();

  return (
    <Modal
      data-testid="withdraw-user-assignment-modal"
      title="Withdraw User Assignment"
      icon={faBan}
      onDismiss={close}
      footer={{
        confirmButton: {
          label: seconds
            ? `Wait ${seconds}s to proceed...`
            : pending
              ? 'Pending...'
              : 'Withdraw Assignment',
          onClick: onConfirm,
          disabled: pending || seconds !== 0,
          dataTestId:
            seconds === 0 ? 'withdraw-user-assignment-submit' : undefined,
        },
      }}
    >
      <Stack gap={1.5}>
        <p data-testid="withdraw-user-assignment-content">
          {userName ? (
            <>
              Please confirm that you want to withdraw the assignment of{' '}
              {userName} to{' '}
              <strong>
                <ExternalLink href={operatorDetails.gtcUrl}>
                  {operatorDetails.shortName}
                </ExternalLink>
              </strong>
              .
            </>
          ) : (
            <>
              Please confirm that you want to withdraw your assignment to{' '}
              <strong>
                <ExternalLink href={operatorDetails.gtcUrl}>
                  {operatorDetails.shortName}
                </ExternalLink>
              </strong>
              .
            </>
          )}
        </p>
      </Stack>
    </Modal>
  );
};
