import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { FC } from 'react';
import { useLocation } from 'react-router-dom';
import type { CreatedAssignmentResponse } from 'src/apis/assignment-service/types';
import { Card } from 'src/components/data-display/card';
import { Spacer } from 'src/components/layout/spacer';
import { Stack } from 'src/components/layout/stack';
import { Link } from 'src/components/navigation/link';
import { Heading } from 'src/components/text/heading';
import { useReferrer } from 'src/hooks/use-referrer';
import {
  CustomDocumentView,
  DocumentView,
} from 'src/pages/settings/assignment-settings/required-information/page';

const SuccessfullyApplied: FC = () => {
  const location = useLocation<{
    createdAssignmentResponse: CreatedAssignmentResponse;
  }>();
  const { createdAssignmentResponse: data } = location.state;
  const assignedUsers = data.userAssignmentRequestedInfo.filter(
    (user) => user.assignmentRequested
  );
  const referrer = useReferrer({
    label: 'Assignments',
    location: '/operators/assignments/overview',
  });

  const requiredDocs = data.requiredDocuments;
  const additionalOrganisationDocuments =
    requiredDocs?.additionalOrganisationDocuments || [];
  const additionalUserDocuments = requiredDocs?.additionalUserDocuments || [];
  const hasOrganisationDocs =
    requiredDocs &&
    (requiredDocs.companyRegisterExtract ||
      requiredDocs.currentCompanyReport ||
      requiredDocs.mostRecentFullYearFinancialReport ||
      requiredDocs.mostRecentInterimFinancialReport ||
      requiredDocs.creditSolvencyCheck ||
      requiredDocs.poa ||
      requiredDocs.creditRating ||
      additionalOrganisationDocuments.length > 0);

  return (
    <Stack gap={1}>
      {referrer.backLink}
      <Card data-testid="successfully-submitted-card">
        <Stack gap={1}>
          {assignedUsers.length > 0 ? (
            <>
              <Heading mode="card">
                <FontAwesomeIcon icon={faCheck} /> Your assignment request was
                successfully submitted.
              </Heading>
              <strong>
                The applications for the following users were successfully
                created:
              </strong>
              <ul data-testid="list-of-users">
                {assignedUsers.map((user) => (
                  <li key={user.userId}>{user.userName}</li>
                ))}
              </ul>
            </>
          ) : (
            <strong data-testid="users-already-have-assignments">
              The selected users already have an assignment with the chosen
              operator. No new user assignments were created.
            </strong>
          )}

          {data.organisationAssignmentRequested && hasOrganisationDocs && (
            <>
              <strong>
                Please send the following documents to the operator to complete
                your application:
              </strong>
              <Stack gap={1.5} data-testid="documents-list">
                {requiredDocs.companyRegisterExtract && (
                  <DocumentView
                    label="Company Register Extract"
                    document={requiredDocs.companyRegisterExtract}
                  />
                )}
                {requiredDocs.currentCompanyReport && (
                  <DocumentView
                    label="Current Company Report"
                    document={requiredDocs.currentCompanyReport}
                  />
                )}
                {requiredDocs.mostRecentFullYearFinancialReport && (
                  <DocumentView
                    label="Most Recent (Full-Year) Financial Report"
                    document={requiredDocs.mostRecentFullYearFinancialReport}
                  />
                )}
                {requiredDocs.mostRecentInterimFinancialReport && (
                  <DocumentView
                    label="Most Recent (Interim) Financial Report"
                    document={requiredDocs.mostRecentInterimFinancialReport}
                  />
                )}
                {requiredDocs.creditSolvencyCheck && (
                  <DocumentView
                    label="Credit/Solvency Check Document"
                    document={requiredDocs.creditSolvencyCheck}
                  />
                )}
                {requiredDocs.poa && (
                  <DocumentView
                    label="PRISMA Shipper Admin Power of Attorney"
                    document={requiredDocs.poa}
                  />
                )}
                {requiredDocs.creditRating && (
                  <DocumentView
                    label="Credit Rating Certification"
                    document={requiredDocs.creditRating}
                    agency={requiredDocs.creditRating.agency}
                  />
                )}
              </Stack>

              {additionalOrganisationDocuments.length > 0 && (
                <Stack gap={1.5} data-testid="custom-documents-list">
                  {additionalOrganisationDocuments.map(
                    (organisationDocument) => (
                      <CustomDocumentView
                        key={organisationDocument.name}
                        additionalDocument={organisationDocument}
                      />
                    )
                  )}
                </Stack>
              )}
            </>
          )}

          {assignedUsers.length > 0 && additionalUserDocuments.length > 0 && (
            <>
              <Spacer />
              <strong>
                Additionally, for every user, the operator requires the
                following documents:
              </strong>
              <Stack gap={1.5} data-testid="user-documents-list">
                {additionalUserDocuments.map((userDocument) => (
                  <CustomDocumentView
                    key={userDocument.name}
                    additionalDocument={userDocument}
                  />
                ))}
              </Stack>
            </>
          )}

          <Spacer />

          <Stack flow="column" gap={1} justifyContent="end">
            {/*  /operators/operator-assignments/ route will be deleted after removal of the old form and changed to /operators/assignments/ */}
            <Link
              mode="button-primary"
              to="/operators/operator-assignments/apply-for-assignment"
              state={{ selectedUsers: [] }}
            >
              Apply for New Assignment
            </Link>
            {/*  /operators/operator-assignments/ route will be deleted after removal of the old form and changed to /operators/assignments/ */}
            <Link
              mode="button-primary"
              to="/operators/operator-assignments/apply-for-assignment"
              state={{
                selectedUsers: data.userAssignmentRequestedInfo.map((user) => ({
                  userId: user.userId,
                  userName: user.userName,
                  userEmail: user.userEmail,
                })),
              }}
            >
              Apply with Same Users
            </Link>
          </Stack>
        </Stack>
      </Card>
    </Stack>
  );
};

export default SuccessfullyApplied;
