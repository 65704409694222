import { useEffect } from 'react';
import { type ShipperReportGenerationSetting } from 'src/apis/monolith/types';
import { useAxios } from 'src/hooks/use-axios';

export function useShipperReportGenerationSetting(
  shipperId: number | string | null
) {
  const request = useAxios(
    (axios, baseConfig, shipperId: number | string) =>
      axios.request<ShipperReportGenerationSetting>({
        ...baseConfig,
        url: `${PRISMA_CONFIG.monolithApiUrl}/shippers/${shipperId}/daily-report-generation`,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!shipperId) return request.setResponse(null);
    request.execute(shipperId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipperId]);

  return request;
}

export type ShipperReportGenerationSettingRequest = ReturnType<
  typeof useShipperReportGenerationSetting
>;
