import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { UsersAssignmentByOperator } from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

export function useUserAssignment({
  assignmentService,
  userAssignmentId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  userAssignmentId: string | null;
}) {
  const userAssignment = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<UsersAssignmentByOperator>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !userAssignmentId ||
      !assignmentService.response.data._links.getUserAssignment
    )
      return;
    const url = urlTemplate
      .parse(assignmentService.response.data._links.getUserAssignment.href)
      .expand({ userAssignmentId });

    userAssignment.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userAssignmentId]);

  if (
    !userAssignmentId ||
    !assignmentService.response.data._links.getUserAssignment
  )
    return;

  return userAssignment;
}

export type UserAssignmentRequest = ReturnType<typeof useUserAssignment>;
