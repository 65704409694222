import type { ButtonHTMLAttributes, FC } from 'react';
import styled from 'styled-components';
import { rotateAnimation } from 'src/styles';

type IsChecked = { isChecked: boolean };

type ToggleLabels = {
  checked: string;
  unchecked: string;
};

const Container = styled.button<IsChecked>`
  position: relative;

  width: 5.4rem;
  height: 2.6rem;
  border-radius: 2rem;
  padding: 0;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }

  transition:
    background 0.2s ease-in-out,
    border 0.2s ease-in-out;
  background: ${({ isChecked }) => (isChecked ? '#5cb85c' : '#efefef')};
  border: 0.1rem solid ${({ isChecked }) => (isChecked ? '#5cb85c' : '#dfdfdf')};

  &:hover:not(:disabled),
  &:focus:not(:disabled) {
    background: ${({ isChecked }) => (isChecked ? '#409440' : '#d7d7d7')};
    border: 0.1rem solid
      ${({ isChecked }) => (isChecked ? '#409440' : '#c7c7c7')};
  }
`;

const Label = styled.div<IsChecked>`
  position: absolute;
  top: 0.7rem;
  left: ${({ isChecked }) => (isChecked ? '0.6rem' : '3.4rem')};

  color: ${({ isChecked }) => (isChecked ? 'white' : '#999')};
  font-size: 1rem;
  user-select: none;
`;

const Switcher = styled.div<IsChecked>`
  width: 2.4rem;
  height: 2.4rem;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 0.4);
  transition: transform 0.2s ease-in-out;
  transform: translateX(${({ isChecked }) => (isChecked ? '2.8rem' : '0')});

  display: flex;
  justify-content: center;
  align-items: center;
  /* &[aria-checked='true'] {
    background: red;
    &:hover:not([disabled]) {
      background: darkred;
    }
  } */
`;

const SpinnerAnimation = styled.div`
  line-height: 1rem;
  ${rotateAnimation};
  width: 1rem;
  height: 1rem;
`;

const Spinner: FC = () => <SpinnerAnimation>◠</SpinnerAnimation>;

export type ToggleProps = {
  isChecked?: boolean;
  loading?: boolean;
  label: string;
  onChange?: () => Promise<void> | void;
  toggleLabels?: ToggleLabels;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const Toggle: FC<ToggleProps> = ({
  isChecked = false,
  onChange,
  label,
  loading = false,
  toggleLabels = { checked: 'Yes', unchecked: 'No' },
  ...rest
}) => {
  return (
    <Container
      isChecked={isChecked}
      type="button"
      role="switch"
      aria-checked={isChecked ? 'true' : 'false'}
      aria-label={label}
      onClick={(e) => {
        onChange?.();
      }}
      disabled={loading}
      {...rest}
    >
      <Label isChecked={isChecked}>
        {isChecked ? toggleLabels.checked : toggleLabels.unchecked}
      </Label>
      <Switcher isChecked={isChecked}>{loading && <Spinner />}</Switcher>
    </Container>
  );
};
