import { memo } from 'react';
import type {
  OrganisationAssignmentStatus,
  TradingMarket,
  UserAssignmentStatusForOperator,
} from 'src/apis/assignment-service/types';
import { MetaLabel } from 'src/components/data-display/meta-label';
import { StatusDisplay } from 'src/components/data-display/status-display';
import { Colors } from 'src/styles';
import { labelsToOptions } from 'src/utils/labels-to-options';

export const assignmentStatusLabels: Record<
  UserAssignmentStatusForOperator,
  string
> = {
  APPROVED: 'Approved',
  WAITING_FOR_APPROVAL: 'Waiting for Approval',
  TEMPORARILY_DISABLED: 'Temporarily Disabled',
  CANCELLED: 'Cancelled',
  NOT_REQUESTED: 'Not Requested',
};

export const assignmentStatusOptions = labelsToOptions(assignmentStatusLabels);

export const colorMap: Record<UserAssignmentStatusForOperator, string> = {
  APPROVED: Colors.success,
  WAITING_FOR_APPROVAL: Colors.warning,
  TEMPORARILY_DISABLED: Colors.brandLight2,
  CANCELLED: Colors.error,
  NOT_REQUESTED: Colors.brandLight2,
};
type Props = {
  value: OrganisationAssignmentStatus | UserAssignmentStatusForOperator;
  tradingMarket?: TradingMarket[];
  'data-testid'?: string;
};

export const AssignmentStatusDisplay = memo<Props>(
  ({ value, tradingMarket, 'data-testid': testId }) => {
    const metaLabels = tradingMarket?.map((market) => {
      switch (market) {
        case 'PRIMARY':
          return (
            <MetaLabel key={market} color="primary">
              Primary Market
            </MetaLabel>
          );
        case 'SECONDARY':
          return (
            <MetaLabel key={market} color="secondary">
              Secondary Market
            </MetaLabel>
          );
      }
    });

    return (
      <StatusDisplay
        status={assignmentStatusLabels[value]}
        iconColor={colorMap[value]}
        metaLabels={<>{metaLabels}</>}
        data-testid={testId}
      />
    );
  }
);
