import { usePageParamsFromSchema } from 'src/hooks/use-page-params-from-schema';
import { getAssignedShipperUsersPageParamsForOperatorsSchema } from 'src/pages/shippers/details/information/schema';

export function useAssignedShipperUsersPageParams() {
  return usePageParamsFromSchema(
    getAssignedShipperUsersPageParamsForOperatorsSchema(),
    {
      groupPrefix: 'assignedShipperUsers',
      filters: {
        userName: { label: 'Name' },
        userEmail: { label: 'Email' },
        userAssignmentStatus: { label: 'User Assignment Status' },
      },
    }
  );
}

export type AssignedShipperUsersPageParams = ReturnType<
  typeof useAssignedShipperUsersPageParams
>;
