import { useEffect } from 'react';
import type { OrganisationSettingsResponse } from 'src/apis/authorization-service/types';
import type { AuthorizationServiceRequest } from 'src/apis/authorization-service/use-authorization-service';
import { useAxios, type Successful } from 'src/hooks/use-axios';

type HookProps = {
  authorizationService?: AuthorizationServiceRequest;
};

type ExecuteProps = {
  authorizationService: Successful<AuthorizationServiceRequest>;
};

export function useOrganisationSettings({ authorizationService }: HookProps) {
  const request = useAxios(
    (axios, baseConfig, { authorizationService }: ExecuteProps) => {
      const url = authorizationService.response.data._links.settings?.href;
      return axios.request<OrganisationSettingsResponse>({
        method: 'GET',
        ...baseConfig,
        url,
      });
    },
    {
      neededOnPageLoad: true,
    }
  );

  useEffect(() => {
    if (!authorizationService?.response) return;
    request.execute({ authorizationService });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorizationService?.response]);

  if (!authorizationService) return;

  return request;
}

export type OrganisationSettingsRequest = ReturnType<
  typeof useOrganisationSettings
>;
