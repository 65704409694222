import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type {
  UsersAssignmentsByOperatorParams,
  UsersAssignmentsByOperatorResponse,
} from 'src/apis/assignment-service/types';
import { useAxios } from 'src/hooks/use-axios';

type HookProps = {
  url?: string;
  operatorId: string;
  params: UsersAssignmentsByOperatorParams;
};

type ExecuteProps = {
  url: string;
  params: UsersAssignmentsByOperatorParams;
};

export function useUserAssignmentsByOperator({
  url,
  operatorId,
  params,
}: HookProps) {
  const shipperAssignments = useAxios(
    (axios, baseConfig, { url, params }: ExecuteProps) =>
      axios.request<UsersAssignmentsByOperatorResponse>({
        ...baseConfig,
        url,
        params,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (!url) return;
    const templatedUrl = urlTemplate.parse(url).expand({ operatorId });

    shipperAssignments.execute({
      url: templatedUrl,
      params,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, params, operatorId]);

  if (!url) return;

  return shipperAssignments;
}

export type UserAssignmentsForOperatorRequest = ReturnType<
  typeof useUserAssignmentsByOperator
>;
