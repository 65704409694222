import { useEffect } from 'react';
import urlTemplate from 'url-template';
import type { ShipperOrganisationAssignment } from 'src/apis/assignment-service/types';
import type { AssignmentServiceRequest } from 'src/apis/assignment-service/use-assignment-service-root';
import type { Successful } from 'src/hooks/use-axios';
import { useAxios } from 'src/hooks/use-axios';

export function useOrganisationAssignment({
  assignmentService,
  orgAssignmentId,
}: {
  assignmentService: Successful<AssignmentServiceRequest>;
  orgAssignmentId: string | null;
}) {
  const organisationAssignment = useAxios(
    (axios, baseConfig, url: string) =>
      axios.request<ShipperOrganisationAssignment>({
        ...baseConfig,
        url,
      }),
    { neededOnPageLoad: true }
  );

  useEffect(() => {
    if (
      !orgAssignmentId ||
      !assignmentService.response.data._links.getOrganisationAssignment
    )
      return;
    const url = urlTemplate
      .parse(
        assignmentService.response.data._links.getOrganisationAssignment.href
      )
      .expand({ orgAssignmentId });

    organisationAssignment.execute(url);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orgAssignmentId]);

  if (
    !orgAssignmentId ||
    !assignmentService.response.data._links.getOrganisationAssignment
  )
    return;

  return organisationAssignment;
}

export type OrgAssignmentRequest = ReturnType<typeof useOrganisationAssignment>;
