import type { FC } from 'react';
import { useEffect } from 'react';
import type { UsersAssignmentByOperator } from 'src/apis/assignment-service/types';
import { useCancelUserAssignment } from 'src/apis/assignment-service/use-cancel-user-assignment';
import type { MyAssignmentsOptionalRequest } from 'src/apis/assignment-service/use-my-assignments';
import type { UserAssignmentsForOperatorRequest } from 'src/apis/assignment-service/use-user-assignments-by-operator';
import { useWithdrawUserAssignment } from 'src/apis/assignment-service/use-withdraw-user-assignment';
import type { PublicOperatorDetailsResponse } from 'src/apis/organisation-service/types';
import { Button } from 'src/components/buttons-and-actions/button';
import { TableActions } from 'src/components/buttons-and-actions/table-actions';
import { CancelUserAssignmentModal } from 'src/components/domain-specifics/assignements/cancel-user-assignment-modal';
import { WithdrawUserAssignmentModal } from 'src/components/domain-specifics/assignements/withdraw-user-assignment-modal';
import type { Successful } from 'src/hooks/use-axios';
import { useModal2 } from 'src/hooks/use-modal';
import { useToast } from 'src/hooks/use-toasts';

export const UserAssignmentActions: FC<{
  userAssignmentDetails: UsersAssignmentByOperator;
  userAssignmentDetailsRequest: Successful<UserAssignmentsForOperatorRequest>;
  operatorDetails: PublicOperatorDetailsResponse;
  assignmentDetailsRequest?: Successful<MyAssignmentsOptionalRequest>;
}> = ({
  userAssignmentDetails,
  userAssignmentDetailsRequest,
  operatorDetails,
  assignmentDetailsRequest,
}) => {
  const withdrawAssignmentApplication = useWithdrawUserAssignment();
  const cancelAssignment = useCancelUserAssignment();
  const notify = useToast();

  const withdrawUserAssignmentApplication =
    userAssignmentDetails._links?.withdrawUserAssignmentApplication;
  const cancelUserAssignment =
    userAssignmentDetails._links?.cancelUserAssignment;

  const withdraw = useModal2(
    withdrawUserAssignmentApplication
      ? {
          modal: (props) => (
            <WithdrawUserAssignmentModal
              onConfirm={() => {
                withdrawAssignmentApplication.execute(
                  withdrawUserAssignmentApplication.href
                );
              }}
              operatorDetails={operatorDetails}
              pending={withdrawAssignmentApplication.pending}
              userName={userAssignmentDetails.userName}
              {...props}
            />
          ),
          trigger: (props) => (
            <Button
              data-testid={`withdraw-user-assignment-application-btn-${userAssignmentDetails.userAssignmentApplicationId}`}
              disabled={withdrawAssignmentApplication.pending}
              {...props}
            >
              {withdrawAssignmentApplication.pending
                ? 'Pending...'
                : 'Withdraw'}
            </Button>
          ),
        }
      : null
  );

  const cancel = useModal2(
    cancelUserAssignment
      ? {
          modal: (props) => (
            <CancelUserAssignmentModal
              onConfirm={() => {
                cancelAssignment.execute(cancelUserAssignment.href);
              }}
              operatorDetails={operatorDetails}
              pending={cancelAssignment.pending}
              userName={userAssignmentDetails.userName}
              {...props}
            />
          ),
          trigger: (props) => (
            <Button
              data-testid="cancel-user-assignment-btn"
              disabled={cancelAssignment.pending}
              {...props}
            >
              {cancelAssignment.pending ? 'Pending...' : 'Cancel'}
            </Button>
          ),
        }
      : null
  );

  useEffect(() => {
    if (!withdrawAssignmentApplication.response) return;
    notify({
      type: 'success',
      children: 'User application was successfully withdrawn.',
    });
    withdraw?.close();
    userAssignmentDetailsRequest.refresh!();
    assignmentDetailsRequest?.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [withdrawAssignmentApplication.response]);

  useEffect(() => {
    if (!cancelAssignment.response) return;
    notify({
      type: 'success',
      children: 'User application was successfully canceled.',
    });
    cancel?.close();
    userAssignmentDetailsRequest.refresh!();
    assignmentDetailsRequest?.refresh!();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cancelAssignment.response]);

  return (
    <>
      <TableActions>
        {withdrawUserAssignmentApplication && withdraw?.trigger}
        {cancelUserAssignment && cancel?.trigger}
      </TableActions>
      {withdraw?.modal}
      {cancel?.modal}
    </>
  );
};
